import React, { useState, useCallback, useEffect } from 'react';
import './WelcomeBonus.scss';
import { IonRow, IonCol, IonButton, IonImg } from '@ionic/react';
import { BRAND_DOMAIN, BRAND_NAME } from '../../constants/Branding';
import { useHistory, NavLink } from 'react-router-dom';
import welcometitle_logo from '../../assets/images/b2cbanner/welcometitle.png';
import SportsBetting from '../../assets/images/faricimage/SportsBetting.png';
import x7Support from '../../assets/images/faricimage/24x7Support.png';
import LiveCasino from '../../assets/images/faricimage/LiveCasino.png';
import bankicon from '../../assets/images/faricimage/bank.png';
import googlepayicon from '../../assets/images/faricimage/googlepay.png';
import paytmicon from '../../assets/images/faricimage/paytm.png';
import Phonepeicon from '../../assets/images/faricimage/Phonepe.png';
import UPIicon from '../../assets/images/faricimage/UPI.png';
import VirtualSports from '../../assets/images/faricimage/VirtualSports.png';
import { Backdrop, Button } from '@material-ui/core';
import Modal from '../../components/Modal/Modal';
import ExtensionSignUp from '../SignUp/ExtensionSignUp';
import Bonus200 from '../../assets/images/faricimage/Bonus200.png';
import { toast } from 'react-toastify';
import { ShowWelcomePage } from '../../constants/WhitelabelConfig';

const WelcomeBonus: React.FC = () => {
  let history = useHistory();
  const [showSignUp, setShowSignUp] = useState<boolean>();

  useEffect(() => {
    if (!ShowWelcomePage?.[BRAND_DOMAIN?.toLowerCase()]) {
      history.push('/home');
    }
  }, []);

  const copyText = (text) => {
    navigator.clipboard.writeText(text);
    toast.success('Text Copied!');
  };

  return (
    <div className="thank-you-ctn">
      <div className="welcome-card">
        <div className="wel-item-ctn">
          <IonRow>
            <IonCol
              sizeLg="12"
              sizeSm="12"
              sizeXs="12"
              className="content_align_cen"
            >
              <div className="welcome_logo">
                <IonImg src={welcometitle_logo} />
              </div>
            </IonCol>
            <IonCol
              sizeLg="12"
              sizeSm="12"
              sizeXs="12"
              className="content_align_cen"
            >
              <div className="text1_align text-light">
                PLAY THE GAME OF WINNING
              </div>
            </IonCol>

            <IonCol
              sizeLg="12"
              sizeSm="12"
              sizeXs="12"
              className="content_align_cen mrl25 mt-40"
            >
              <IonRow>
                <IonImg
                  className="w-90"
                  src={Bonus200}
                  onClick={() => copyText('FIRSTDEPOSIT')}
                />
              </IonRow>
            </IonCol>

            <IonCol
              sizeLg="12"
              sizeSm="12"
              sizeXs="12"
              className="content_align_cen whatsapp-promo"
            >
              <div className="text_align_WelBonus text-light">
                WhatsApp us the promo code.
              </div>
            </IonCol>

            {BRAND_DOMAIN.toString() === 'faircric.in' ? (
              <>
                <IonCol
                  sizeLg="12"
                  sizeSm="12"
                  sizeXs="12"
                  className="content_align_cen"
                >
                  {/* <a> */}
                  <IonRow>
                    <IonCol
                      sizeLg="6"
                      sizeSm="6"
                      sizeXs="6"
                      className="content_align_cen"
                    >
                      <IonButton
                        onClick={() => setShowSignUp(true)}
                        className="my-bets-btn withdraw-btn actions-btn claim-btn-fair"
                      >
                        Claim Now
                      </IonButton>
                    </IonCol>
                    <IonCol
                      sizeLg="6"
                      sizeSm="6"
                      sizeXs="6"
                      className="content_align_cen"
                    >
                      <IonButton
                        onClick={() => setShowSignUp(true)}
                        className="my-bets-btn withdraw-btn actions-btn claim-btn-fair"
                      >
                        Sign Up
                      </IonButton>
                    </IonCol>
                  </IonRow>
                  {/* </a> */}
                </IonCol>
              </>
            ) : null}

            <IonCol
              sizeLg="12"
              sizeSm="12"
              sizeXs="12"
              className="content_align_cen"
            >
              <div className="text1_align">Available on</div>
            </IonCol>
            <IonCol
              sizeLg="12"
              sizeSm="12"
              sizeXs="12"
              className="content_align_cen payment-method"
            >
              <div className="payment-icons-block">
                <img src={bankicon} className="icon_bank_align" />
                <img src={paytmicon} className="icon_paytm_align" />
                <img src={UPIicon} className="icon_upi_align" />
                <img src={Phonepeicon} className="icon_phonepe_align" />
                <img src={googlepayicon} className="icon_googleplay_align" />
              </div>
            </IonCol>
          </IonRow>
        </div>
      </div>
      <Backdrop className="backdrop-ctn" open={showSignUp}>
        <Modal
          open={showSignUp}
          closeHandler={() => setShowSignUp(false)}
          title="Sign Up"
          size="xs"
          customClass="sign-up-modal"
        >
          <ExtensionSignUp closeHandler={() => setShowSignUp(false)} />
        </Modal>
      </Backdrop>
    </div>
  );
};

export default WelcomeBonus;
