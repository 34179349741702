import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../models/RootState';
import { IonRow, IonCol } from '@ionic/react';
import {
  TextField,
  FormHelperText,
  FormControl,
  OutlinedInput,
  InputAdornment,
  Button,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { IonSpinner } from '@ionic/react';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import * as Yup from 'yup';

import API from '../../api';
import webtitleImg from '../../assets/images/brand/title.png';
import HorizontalLabelPositionBelowStepper from './Steps';
import { BRAND_DOMAIN, BRAND_NAME } from '../../constants/Branding';
import './SignUp.scss';
import { DomainConfig } from '../../models/DomainConfig';
import SVLS_API from '../../svls-api';

type StoreProps = {
  closeHandler: Function;
  domainConfig: DomainConfig;
};

const ExtensionSignUp: React.FC<StoreProps> = ({ domainConfig }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [userErrorMsg, setUserErrorMsg] = useState<string>('');
  const [otp, setOtp] = useState<number>(null);
  const [referralCode, setReferralCode] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const [agreeTerms, setAgreeTerms] = useState<boolean>(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [phone, setPhone] = useState<any>('');
  const [country, setCountry] = useState<any>('');
  const [phoneFormat, setPhoneFormat] = useState<any>('');
  const [otpTimer, setOtpTimer] = useState<number>();
  const [otpEnterFields, setOtpEnterFields] = useState<boolean>(false);
  const [otpErrorMessage, setOtpErrorMessage] = useState<string>('');
  const [phoneNumbeErrorMsg, setPhoneNumbeErrorMsg] = useState<string>('');
  const [verificationErrorMsg, setVerificationErrorMsg] = useState<string>('');
  const [signUpErrorMsg, setsignUpErrorMsg] = useState<string>('');

  const [phoneNumberExists, setPhoneNumberExists] = useState<boolean>(false);
  let history = useHistory();
  const { pathname } = useLocation();

  const formik = useFormik({
    initialValues: {
      fullname: '',
      username: '',
      password: '',
      // phoneNumber: '',
      address: '',
      otp: null,
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .required('Required')
        .min(4, 'The User Name field must be at least 4 characters'),
      fullname: Yup.string().required('Required'),
      password: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      let postBody = {
        fullName: values.fullname.trim(),
        username: values.username.toLowerCase(),
        password: values.password,
        phoneNumber: phone,
        otp: otp,
      };
      setsignUpErrorMsg('');
      try {
        const response: any = await SVLS_API.post(
          '/account/v2/accounts/signup',
          postBody,
          {}
        );

        toast.success('Signed up successfully');
        // login after the sign up is successful.
        login(postBody.username, postBody.password);
      } catch (err) {
        setsignUpErrorMsg(err?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    },
  });

  const login = async (username: string, password: string) => {
    setLoading(true);
    try {
      const loginRequest = {
        username: username,
        password: password,
      };
      const response = await SVLS_API.post('/account/v2/login', loginRequest);

      // redirect to home if the login successful.
      redirectToHome(response.data);
    } catch (err) {
      toast.error(err.response.data.message);
      // redirect to login page if the login fails.
      // loginFailed(err.response.data.message);
      redirectToLogin();
    } finally {
      setLoading(false);
    }
  };

  const redirectToHome = (token: string) => {
    history.push('/login');
  };

  const redirectToLogin = () => {
    history.push('/login');
  };

  const showPasswordClickHandler = () => {
    setShowPassword(!showPassword);
  };

  const handleOtpTimer = (time) => {
    if (time >= 0) {
      setOtpTimer(time);
      setTimeout(() => {
        handleOtpTimer(time - 1);
      }, 1000);
    } else {
      setTimeout(() => {
        setOtpTimer(undefined);
      }, 1000);
    }
  };

  const handleChange = (e) => {
    const { target } = e;
    const { value, name } = target;
    if (name === 'phone') {
      setPhone(value);
    } else if (name === 'otp') {
      setOtp(value);
    } else if (name === 'referralCode') {
      setReferralCode(value);
    }
  };

  const sendOTP = async () => {
    setPhoneNumbeErrorMsg('');
    try {
      if (country.format?.length === phoneFormat?.length) {
        const response: any = await SVLS_API.post(
          `/account/v2/otp/?mobileNumber=${phone}`
        );
        if (response.status === 204) {
          handleOtpTimer(60);
          setOtpEnterFields(true);
        }
      }
    } catch (err) {
      setPhoneNumbeErrorMsg(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const checkPhoneNumberExists = async (phoneNumber: string) => {
    setLoading(true);
    try {
      const response: any = await SVLS_API.get(
        `/account/v2/users/phones/${phoneNumber}/:exists`
      );
      if (response.status === 200) {
        if (response.data === true) {
          setPhoneNumbeErrorMsg('Phone number already exists.');
          setPhoneNumberExists(true);
        } else {
          setPhoneNumbeErrorMsg('');
          setPhoneNumberExists(false);
        }
      }
    } catch (err) {
      setPhoneNumbeErrorMsg(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (phone && phone?.length - country?.dialCode.length + 1 > 10) {
      checkPhoneNumberExists(phone);
    } else {
      setPhoneNumbeErrorMsg('');
    }
  }, [phone]);

  const validateOtp = async () => {
    setVerificationErrorMsg('');
    try {
      if (country.format.length === phoneFormat.length) {
        const response: any = await SVLS_API.post(
          `/account/v2/otp/validate?mobileNumber=${phone}&otp=${otp}`,
          {}
        );
        if (response.status === 204) {
          setActiveStep(1);
          setOtpErrorMessage('');
        }
      }
    } catch (err) {
      setVerificationErrorMsg(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleNextStep = () => {
    if (phone === '') {
      toast.error('Please Enter Phone Number');
      return;
    }
    if (!otp || otp.toString().length !== 6) {
      setOtpErrorMessage('Please Enter Valid OTP');
      return;
    }
    setOtpErrorMessage('');
    setLoading(true);
    validateOtp();
  };

  const checkUserName = async (e) => {
    setsignUpErrorMsg('');
    try {
      const { target } = e;
      const { value } = target;
      if (value.length > 3) {
        const response: any = await SVLS_API.get(
          `/account/v2/users/${value}/:exists`
        );
        if (response.status === 200) {
          if (response.data) {
            setUserErrorMsg('User name already exists');
          } else {
            setUserErrorMsg('');
          }
        }
      }
    } catch (err) {
      setsignUpErrorMsg('Something went wrong, Please try again');
      // toast.error('Something went wrong, Please try again');
    }
  };

  return (
    <div className={'sign-up-ctn faircric-signup'}>
      <IonRow>
        <IonCol sizeLg="12" sizeXs="12" className="card-col">
          <div className="title-row title-logo">
            <img src={webtitleImg} alt="title" className="logo" />
          </div>
          <div className="card-bg">
            <div className="card-section">
              {activeStep === 0 ? (
                <>
                  <div className="card">
                    <div className="page-title">Create your account</div>

                    <IonRow>
                      {' '}
                      <HorizontalLabelPositionBelowStepper
                        activeStep={activeStep}
                      />{' '}
                    </IonRow>
                    <IonRow>
                      <IonCol sizeXs="12" sizeLg="12" className="input-col">
                        <div className="input-row">
                          <div className="label">Phone Number</div>
                          <PhoneInput
                            country={'in'}
                            placeholder="Enter Phone Number"
                            value={phone}
                            onChange={(value, country, e, formattedValue) => {
                              setPhone(value);
                              setCountry(country);
                              setPhoneFormat(formattedValue);
                            }}
                            disabled={otpEnterFields}
                          />
                          {phoneNumbeErrorMsg ? (
                            <FormHelperText error id="my-helper-text">
                              {phoneNumbeErrorMsg}
                            </FormHelperText>
                          ) : null}
                        </div>
                      </IonCol>
                    </IonRow>
                    {!otpEnterFields ? (
                      <>
                        <IonRow>
                          <IonCol sizeLg="12" sizeXs="12" className="input-col">
                            <FormGroup className="mt-0">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={() => setAgreeTerms(!agreeTerms)}
                                  />
                                }
                                label={`
                            I agree to the all terms & Conditions of ${BRAND_NAME}.`}
                              />
                            </FormGroup>
                          </IonCol>
                        </IonRow>

                        <div className="btn-section">
                          <Button
                            className="btn"
                            color="primary"
                            type="submit"
                            variant="contained"
                            endIcon={
                              loading ? <IonSpinner name="lines-small" /> : ''
                            }
                            disabled={
                              !agreeTerms ||
                              phone === '' ||
                              phone?.length - country?.dialCode.length + 1 <=
                                10 ||
                              phoneNumberExists
                            }
                            onClick={() => sendOTP()}
                          >
                            Send OTP
                          </Button>
                        </div>
                      </>
                    ) : null}

                    {otpEnterFields ? (
                      <>
                        <IonRow>
                          <IonCol sizeLg="12" sizeXs="12" className="input-col">
                            <div className="input-row flex-column">
                              <div className="label">OTP *</div>
                              <TextField
                                className="number-input"
                                type="number"
                                name="otp"
                                variant="outlined"
                                placeholder="Enter OTP"
                                error={
                                  otpErrorMessage !== ''
                                    ? true
                                    : false || verificationErrorMsg !== ''
                                    ? true
                                    : false
                                }
                                helperText={
                                  otpErrorMessage !== ''
                                    ? otpErrorMessage
                                    : null || verificationErrorMsg !== ''
                                    ? verificationErrorMsg
                                    : null
                                }
                                onKeyPress={(evt) => {
                                  if (
                                    (evt.which != 8 &&
                                      evt.which != 0 &&
                                      evt.which < 48) ||
                                    evt.which > 57
                                  ) {
                                    evt.preventDefault();
                                  }
                                }}
                                onChange={(e) => handleChange(e)}
                              />
                              <div
                                className="label link"
                                onClick={() => {
                                  if (!otpTimer) {
                                    sendOTP();
                                  }
                                }}
                              >
                                {otpTimer !== undefined &&
                                otpTimer !== null &&
                                otpTimer >= 0
                                  ? `Resend in ${otpTimer} sec`
                                  : 'Send OTP'}
                              </div>
                            </div>
                          </IonCol>
                        </IonRow>
                        <div className="btn-section">
                          <Button
                            className="btn"
                            color="primary"
                            type="submit"
                            variant="contained"
                            endIcon={
                              loading ? <IonSpinner name="lines-small" /> : ''
                            }
                            disabled={!agreeTerms}
                            onClick={() => handleNextStep()}
                          >
                            Verify OTP
                          </Button>
                        </div>
                      </>
                    ) : null}
                  </div>
                </>
              ) : null}
              {activeStep === 1 ? (
                <>
                  <form
                    onSubmit={formik.handleSubmit}
                    className="card"
                    autoComplete="off"
                  >
                    <div className="page-title">Create your account</div>
                    <IonRow>
                      {' '}
                      <HorizontalLabelPositionBelowStepper
                        activeStep={activeStep}
                      />{' '}
                    </IonRow>
                    <IonRow className="input-row">
                      <div className="form-control">
                        <div className="label">Username *</div>
                        <TextField
                          className="login-input-field user-name"
                          type="text"
                          placeholder="Enter Username"
                          variant="outlined"
                          name="username"
                          onKeyUp={(e) => checkUserName(e)}
                          error={
                            (formik.touched.username &&
                              formik.errors.username) ||
                            userErrorMsg !== ''
                              ? true
                              : false
                          }
                          helperText={
                            formik.touched.username && formik.errors.username
                              ? formik.errors.username
                              : userErrorMsg !== ''
                              ? userErrorMsg
                              : null
                          }
                          {...formik.getFieldProps('username')}
                        />
                      </div>
                    </IonRow>

                    <IonRow className="input-row">
                      <div className="form-control">
                        <div className="label">Full Name *</div>
                        <TextField
                          className="login-input-field user-name"
                          type="text"
                          name="fullname"
                          variant="outlined"
                          placeholder="Enter Fullname"
                          error={
                            formik.touched.fullname && formik.errors.fullname
                              ? true
                              : false
                          }
                          helperText={
                            formik.touched.fullname && formik.errors.fullname
                              ? formik.errors.fullname
                              : null
                          }
                          {...formik.getFieldProps('fullname')}
                        />
                      </div>
                    </IonRow>

                    <IonRow className="input-row">
                      <div className="form-control">
                        <div className="label">Password *</div>
                        <FormControl
                          className="login-input-field pwd-field"
                          variant="outlined"
                          error={
                            formik.touched.password && formik.errors.password
                              ? true
                              : false
                          }
                        >
                          <OutlinedInput
                            id="standard-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            name="password"
                            placeholder="Enter Password"
                            {...formik.getFieldProps('password')}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={showPasswordClickHandler}
                                  onMouseDown={showPasswordClickHandler}
                                >
                                  {showPassword ? (
                                    <Visibility className="no-margin" />
                                  ) : (
                                    <VisibilityOff className="no-margin" />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                          {formik.touched.password && formik.errors.password ? (
                            <FormHelperText error id="my-helper-text">
                              {formik.errors.password}
                            </FormHelperText>
                          ) : null}
                          {signUpErrorMsg ? (
                            <FormHelperText error id="my-helper-text">
                              {signUpErrorMsg}
                            </FormHelperText>
                          ) : null}
                        </FormControl>
                      </div>
                    </IonRow>

                    <IonRow></IonRow>
                    <IonRow className="button-row">
                      {/* <Button
                        className="back-form-btn"
                        color="secondary"
                        variant="contained"
                        onClick={() => {
                          setOtp(null);
                          setActiveStep(0);
                        }}
                      >
                        Back
                      </Button> */}
                      <Button
                        className="signup-btn"
                        color="primary"
                        endIcon={
                          loading ? <IonSpinner name="lines-small" /> : ''
                        }
                        type="submit"
                        variant="contained"
                        disabled={!(formik.isValid && userErrorMsg === '')}
                      >
                        Create Account
                      </Button>
                    </IonRow>
                  </form>
                </>
              ) : null}
            </div>
          </div>
        </IonCol>
      </IonRow>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    domainConfig: state.common.domainConfig,
  };
};

export default connect(mapStateToProps)(ExtensionSignUp);
